import { getApp, getApps, initializeApp } from 'firebase/app';
import {
  getAuth,
  linkWithCredential,
  OAuthProvider,
  signInWithRedirect,
} from 'firebase/auth';

const firebaseConfig =
  JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG) ?? {};

if (getApps().length === 0) {
  initializeApp(firebaseConfig);
}

const auth = getAuth(getApp());

export const linkAccounts = async (user) => {
  const pendingCredential = sessionStorage.getItem('pendingCredential');

  if (pendingCredential) {
    const authCredential = JSON.parse(pendingCredential);
    await linkWithCredential(user, authCredential);

    sessionStorage.removeItem('pendingCredential');
  }
};

export const signOut = async () => {
  try {
    await auth.signOut();

    return true;
  } catch {
    return false;
  }
};

export const signInWithSocial = async (providerId) => {
  const provider = new OAuthProvider(providerId);
  await signInWithRedirect(auth, provider);
};

export default auth;
