/* eslint-disable */
import { User } from 'firebase/auth';
import { Dispatch } from 'react';
import { Action } from 'redux';

import apiClient, { API_TYPES } from '@services/hafh/api';
import { V2GetFlightsParams } from '@utils/types/request';
import { V2FlightReservationNewFlightsInput } from '@utils/types/v2-flight-reservation-new-flights';

const api = apiClient(API_TYPES.API);

const initialState: { v2Flights: V2FlightReservationNewFlightsInput[] } = {
  v2Flights: [],
};

// action types
export const SET_V2_FLIGHTS = 'hafh/flights/SET_V2_FLIGHTS';

// reducers
const v2Flights = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SET_V2_FLIGHTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const setV2Flights = (input: V2FlightReservationNewFlightsInput) => ({
  type: SET_V2_FLIGHTS,
  payload: input,
});

/**
 *
 * @param :date, :departure_airport_id, :arrival_airport_id, :adult, :child
 * @param locale
 * @returns
 */
export const getV2Flights =
  (params: V2GetFlightsParams, locale: string, authUser: User) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.get(
      'v2_flight_reservations/flights',
      params,
      locale,
      authUser
    );
    if (res) {
      dispatch(setV2Flights(res));
    }
  };

export default v2Flights;
