/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */

/* Plan code and id */
export const NEW_STANDARD_CODE = 'new_standard';

export const BASIC_CODE = 'basic';

export const KR_BASIC_CODE = 'kr_basic';

export const KOREAN_STANDARD_CODE = 'kr_standard';

export const GLB_BASIC_CODE = 'glb_basic';

export const SNOOZE_PLAN_KIND = 'snooze';

export const FREE_PLAN_KIND = 'free';

export const BASIC_PLAN_CODES = [BASIC_CODE, KR_BASIC_CODE, GLB_BASIC_CODE];

export const NEW_PLAN_CODES = [NEW_STANDARD_CODE, BASIC_CODE];

export const JAL_PLAN_CODES = [
  'jal_light',
  'jal_standard',
  'jal_premium',
  'jal_pair',
  'jal_pair_plus',
];

/* Lang and Locales */
export const LOCALES = new Set<string>(['en', 'ja', 'ko', 'zh-tw']);

export const COUNTRY_CODES = {
  japan: 'jp',
  korea: 'kr',
  global: 'glb',
  usa: 'us',
};

export const COUNTRY_IDS = {
  japan: 1,
  taiwan: 2,
  korea: 104,
  global: 999,
  usa: 24,
};

export const LANGUAGE_CODES = {
  japanese: 'ja',
  english: 'en',
  korean: 'ko',
  taiwanese: 'zh-tw',
} as const;

export const ALL_LANGUAGES = [
  {
    label: '日本語',
    value: 1,
    key: 'ja',
  },
  {
    label: 'English',
    value: 2,
    key: 'en',
  },
  {
    label: '한국어',
    value: 3,
    key: 'ko',
  },
  {
    label: '繁體中文',
    value: 4,
    key: 'zh-tw',
  },
] as const;

export const SUPPORT_LANGUAGES: Record<
  string,
  Array<(typeof ALL_LANGUAGES)[number]>
> = {
  glb: ALL_LANGUAGES.filter((language) =>
    ['en', 'zh-tw'].includes(language.key)
  ),
  jp: ALL_LANGUAGES.filter((language) => ['en', 'ja'].includes(language.key)),
  kr: ALL_LANGUAGES.filter((language) => ['en', 'ko'].includes(language.key)),
};

export const DATE_FORMAT = {
  ja: 'M月D日',
  ko: 'M월 D일',
  en: 'MMM D',
  'zh-tw': 'M月D日',
};

/* UI settings */
export const BREAKPOINT = {
  // FIXME: want to refactor the breakpoints
  mobileXs: 375,
  mobileS: 480,
  mobile: 768,
  tablet: 955,
  desktop: 769,
  desktopL: 1024,
  desktopXl: 1280,
};

export const SVG_SIZES = {
  xxxs: 8,
  xxs: 12,
  xs: 16,
  sm: 20,
  md: 24,
  mm: 28,
  ml: 32,
  lg: 48,
  xl: 64,
};

export const DEFAULT_IMAGE = `${process.env.NEXT_PUBLIC_GCS_URL}/ogp/top.jpg`;

/* Property and Room */
export const ATTRIBUTE_IDS = {
  commonEquipment: 14,
  service: 43,
  amenity: 34,
};

export const PROPERTY_TYPE_TAGS = [
  { tagName: 'hotel', id: 4 },
  { tagName: 'resort', id: 2 },
  { tagName: 'businessHotel', id: 3 },
  { tagName: 'boutiqueHotel', id: 5 },
  { tagName: 'luxuryHotel', id: 6 },
  { tagName: 'ryokan', id: 7 },
  { tagName: 'glampingSites', id: 8 },
  { tagName: 'entireHouse', id: 9 },
  { tagName: 'guestHouse', id: 10 },
  { tagName: 'capsuleHotel', id: 11 },
  { tagName: 'condominium', id: 12 },
  { tagName: 'other', id: 13 },
];

export const COMMON_EQUIPMENTS: { [key: string]: number } = {
  publicBath: 33,
  hotSpringBath: 30,
  sauna: 31,
  openAirBath: 32,
  workingSpace: 16,
  wifi: 29,
  trainingRoom: 24,
  swimmingPool: 25,
  parking: 18,
  cafeBar: 26,
  restaurant: 27,
  laundromat: 17,
  clothesIron: 21,
  microwave: 20,
  kitchen: 28,
  eventSpace: 15,
  handicappedAccessible: 22,
  elevator: 23,
  sharedBathroomAndToilet: 19,
  closeToStation: 87,
  frontdesk24: 52,
  lateCheckOut: 53,
  earlyCheckIn: 54,
};

export const AMENITY_FILTERS: { [key: string]: number } = {
  publicBath: 33,
  sauna: 31,
  hotSpringBath: 30,
  wifi: 29,
  parking: 18,
  trainingRoom: 24,
  closeToStation: 87,
  frontdesk24: 52,
  earlyCheckIn: 54,
  lateCheckOut: 53,
};

export const ROOM_ATTRIBUTE_IDS = {
  roomType: 55,
  equipment: 69,
};

/* Rank */
export const REQUIRED_MONTHS_FOR_RANK = {
  silver: 3,
  gold: 6,
  platinum: 12,
};

export const VIP_RANKS = {
  regular: 'regular',
  silver: 'silver',
  gold: 'gold',
  platinum: 'platinum',
};

export const VIP_DISCOUNT_RATE = {
  silver: 5,
  gold: 7,
  platinum: 10,
};

export const VIP_NEXT_RANK = {
  regular: 'silver',
  silver: 'gold',
  gold: 'platinum',
  platinum: null,
};

export const FEATURED_PREFECTURES = [
  {
    img: `${process.env.NEXT_PUBLIC_GCS_URL}/browse/featured-prefectures/hokkaido.jpg`,
    name: { ja: '北海道', en: 'Hokkaido', ko: '홋카이도', 'zh-tw': '北海道' },
    displayName: {
      ja: '北海道',
      en: 'Hokkaido',
      ko: '홋카이도',
      'zh-tw': '北海道',
    },
  },
  {
    img: `${process.env.NEXT_PUBLIC_GCS_URL}/browse/featured-prefectures/ishikawa.jpg`,
    name: { ja: '石川県', en: 'Ishikawa', ko: '이시카와', 'zh-tw': '石川縣' },
    displayName: {
      ja: '石川',
      en: 'Ishikawa',
      ko: '이시카와',
      'zh-tw': '石川',
    },
  },
  {
    img: `${process.env.NEXT_PUBLIC_GCS_URL}/browse/featured-prefectures/tokyo.jpg`,
    name: { ja: '東京都', en: 'Tokyo', ko: '도쿄', 'zh-tw': '東京都' },
    displayName: { ja: '東京', en: 'Tokyo', ko: '도쿄', 'zh-tw': '東京' },
  },
  {
    img: `${process.env.NEXT_PUBLIC_GCS_URL}/browse/featured-prefectures/osaka.jpg`,
    name: { ja: '大阪府', en: 'Osaka', ko: '오사카', 'zh-tw': '大阪府' },
    displayName: { ja: '大阪', en: 'Osaka', ko: '오사카', 'zh-tw': '大阪' },
  },
  {
    img: `${process.env.NEXT_PUBLIC_GCS_URL}/browse/featured-prefectures/kyoto.jpg`,
    name: { ja: '京都府', en: 'Kyoto', ko: '교토', 'zh-tw': '京都府' },
    displayName: { ja: '京都', en: 'Kyoto', ko: '교토', 'zh-tw': '京都' },
  },
  {
    img: `${process.env.NEXT_PUBLIC_GCS_URL}/browse/featured-prefectures/fukuoka.jpg`,
    name: { ja: '福岡県', en: 'Fukuoka', ko: '후쿠오카', 'zh-tw': '福岡縣' },
    displayName: {
      ja: '福岡',
      en: 'Fukuoka',
      ko: '후쿠오카',
      'zh-tw': '福岡',
    },
  },
  {
    img: `${process.env.NEXT_PUBLIC_GCS_URL}/browse/featured-prefectures/nagasaki.jpg`,
    name: { ja: '長崎県', en: 'Nagasaki', ko: '나가사키', 'zh-tw': '長崎縣' },
    displayName: {
      ja: '長崎',
      en: 'Nagasaki',
      ko: '나가사키',
      'zh-tw': '長崎',
    },
  },
  {
    img: `${process.env.NEXT_PUBLIC_GCS_URL}/browse/featured-prefectures/okinawa.jpg`,
    name: { ja: '沖縄県', en: 'Okinawa', ko: '오키나와', 'zh-tw': '沖繩縣' },
    displayName: {
      ja: '沖縄',
      en: 'Okinawa',
      ko: '오키나와',
      'zh-tw': '沖繩',
    },
  },
];

export const allRegion = 'allRegion';
const hokkaido = 'hokkaido';
const tohoku = 'tohoku';
const kanto = 'kanto';
const chubu = 'chubu';
const kinki = 'kinki';
const chugoku = 'chugoku';
const shikoku = 'shikoku';
const kyushu = 'kyushu';
const okinawa = 'okinawa';

export const areaTabKeys = [
  allRegion,
  hokkaido,
  tohoku,
  kanto,
  chubu,
  kinki,
  chugoku,
  shikoku,
  kyushu,
  okinawa,
];

export const coinTabRanges = {
  '0~100': {
    min: 0,
    max: 100,
  },
  '101~300': {
    min: 101,
    max: 300,
  },
  '301~600': {
    min: 301,
    max: 600,
  },
  '601~1000': {
    min: 601,
    max: 1000,
  },
  '1000+': {
    min: 1001,
  },
};

export const HERO_IMAGES_PROPERTIES_LIST = [
  '2114',
  '3448',
  '3446',
  '1295',
  '2390',
  '1542',
  '3236',
  '440',
];

export const ADLP_PROPERTIES_LIST = [2666, 2531, 905, 1537, 960];

export const ADLP_STANDARD_PROPERTIES_LIST = [2328, 911, 305, 2291, 2351, 1935];

export const TARGET_FAIRFIELD_LIST = [
  2885, 2886, 2887, 2870, 2871, 2874, 2883, 2872, 2876, 2880, 2873, 2877, 2879,
  2884, 2875, 2878, 2881, 2882, 2888, 2892, 2890, 2896, 2889, 2893, 2891, 2894,
  2895, 2897, 2898,
];

/* Link */
export const ZENDESK_LINK = 'https://hafh.zendesk.com/hc';

/* Campaign */
export const SIGN_UP_COIN_PACKAGE_CODE: string[] = [
  'package-202409a',
  'package-202409b',
  'package-202409c',
];

export const COIN_PACK_CAMPAIGN_TOPIC_ID = 19_230;

export const SIGN_UP_CAMPAIGN_CODE = '20240424_jp_signup';

export const FAIRFIELD_CAMPAIGN_TOPIC_IDS = {
  ja: '21253',
  en: '21256',
  ko: '21257',
  'zh-tw': '21258',
};

/* Others */
export const CURRENT_TERM = 'current-term';

export const NEXT_TERM = 'next-term';

export const CONTACT_TYPES = ['neighbor', 'interview', 'recruit', 'other'];

export const GENDERS = ['female', 'male', 'non_binary'];

export const MAP_PROPERTIES_PER_PAGE = 24;

export const RESERVATIONS_PER_PAGE = 24;

export const DEFAULT_COIN_RANGE = {
  min: 0,
  max: 10_000,
};

export const CHANNEL_MANGER_POINT = 5;

export const PROPERTY_COUNT = 10_000;

export const AGE_REQUIREMENT_18 = 18;

export const AGE_REQUIREMENT_19 = 19;

export const MAX_DAYS_IN_ADVANCE = 90;

export const FLIGHT_RESERVATION_DATES = 28;

export const MAX_FLIGHT_PASSENGER = 9;

export const INVITER_COIN = 100;

export const INVITEE_COIN = 100;

export const INVITEE_RETENTION_COIN = 150;

export const ALPHABET_AND_SPACE_REGEX = /^[ A-Za-z]*$/;

/*eslint no-irregular-whitespace: ["error", { "skipRegExps": true }]*/
export const KATAKANA_AND_SPACE_REGEX = /^[　ァ-ヴー]*$/;

export const AUTH_FORM_TYPES = {
  signIn: 'signin',
  signUp: 'signup',
} as const;

export const inboxTabs = [
  'property',
  'support',
  'news',
  'notifications',
] as const;

export enum LEGAL_PAGES {
  GUIDE_OF_TRAVEL_STORIES = 'guide-of-travel-stories',
  PRIVACY_POLICY = 'privacy-policy',
  PRIVACY_POLICY_V2 = 'privacy-policy-v2',
  TERMS_OF_FLIGHT = 'terms-of-flight',
  TERMS_OF_GENERAL = 'terms-of-general',
  TERMS_OF_GENERAL_LIMITATION = 'terms-of-general-limitationrule',
  TERMS_OF_SERVICE = 'terms-of-service',
  TERMS_OF_SERVICE_V2 = 'terms-of-service-v2',
  TERMS_OF_TRAVEL_STORIES = 'terms-of-travel-stories',
}

export const HTTP_UNPROCESSABLE_ENTITY_CODE = 422;

export const PAYMENT_PROVIDERS = {
  stripe: 'stripe',
  gmo: 'gmo',
};

export const JAPAN_AREA_ID = 8;

export const TOKYO_AREA_ID = 9;

export const MAX_GUEST_COUNT_FOR_SEARCH = 12;

const PROTOCOL =
  process.env.NODE_ENV === 'development' ? 'http://' : 'https://';

export const PUBLIC_IMAGES_URL = `${PROTOCOL}${process.env.NEXT_PUBLIC_API_DOMAIN}`;

export const SHORTAGE_COIN_REDIRECT_KEY = 'shortage_coin_redirect_url';

export const REFERRAL_CODE_LOCALSTORAGE_KEY = 'referral_code';

export const INVOICE_START_YEAR = 2023;

export const INVOICE_START_MONTH = 9;

export const MIN_COIN_PURCHASE_UNIT = 1;

export const SUBSCRIBE_ATTEMPT_LOCALSTORAGE_KEY = 'subscribe-attempt';

export const HEADER_HEIGHT_MOBILE = 56;

export const HEADER_HEIGHT_DESKTOP = 64;

export const FIRST_STAGE_BADGE_TOPIC_ID = 19_260;

export const FLIGHT_TICKET_PRIZE_CAMPAIGN_START_DATE =
  '2024-11-21T12:00:00+0900';

export const FLIGHT_TICKET_PRIZE_CAMPAIGN_STOP_DATE =
  '2024-11-27T23:59:59+0900';

export const JAL_CAMPAIGN_202409_START = '2024-09-27T18:00:00+0900';

export const JAL_CAMPAIGN_202409_END = '2024-09-30T23:59:59+0900';

export const JAL_CAMPAIGN_202409_TOPIC_URL =
  'https://www.hafh.com/topics/21241';

export const TRAVEL_STORIES_TABS = ['latest', 'popular', 'me', 'liked'];

// ref: https://firebase.google.com/docs/auth/admin/errors?hl=en
export const FIRE_BASE_AUTH_ERROR_CODE = {
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
};

export const JMB_VALIDATION_REGEX = /^\d{7}(\d{2})?$/;
