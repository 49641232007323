/* eslint-disable */
import { User } from 'firebase/auth';
import { Dispatch } from 'react';

import apiClient, { API_TYPES } from '@services/hafh/api';
import { V2FlightReservation } from '@utils/types';
import { V2FlightReservationNewOutput } from '@utils/types/v2-flight-reservation-new';
import { V2FlightReservationShow } from '@utils/types/v2-flight-reservation-show';

const api = apiClient(API_TYPES.API);

const defaultFlightReservationsObj: V2FlightReservation[] = [];

const initialState = {
  upcoming: defaultFlightReservationsObj,
  past: defaultFlightReservationsObj,
};

// action types
type Action =
  | {
      type: 'hafh/v2FlightReservations/SET_FLIGHT_RESERVATION';
      payload: V2FlightReservationShow;
    }
  | {
      type: 'hafh/v2FlightReservations/SET_UPCOMING_FLIGHT_RESERVATIONS';
      payload: {
        upcoming: V2FlightReservation[];
      };
    }
  | {
      type: 'hafh/v2FlightReservations/SET_PAST_FLIGHT_RESERVATIONS';
      payload: {
        past: V2FlightReservation[];
      };
    };

// reducers
const V2FlightReservations = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'hafh/v2FlightReservations/SET_FLIGHT_RESERVATION':
      return {
        ...state,
        ...action.payload,
      };
    case 'hafh/v2FlightReservations/SET_UPCOMING_FLIGHT_RESERVATIONS':
      return {
        ...state,
        upcoming: action.payload,
      };
    case 'hafh/v2FlightReservations/SET_PAST_FLIGHT_RESERVATIONS':
      return {
        ...state,
        past: action.payload,
      };
    default:
      return state;
  }
};

// actions
export const getUpcomingFlightReservations =
  (locale: string, authUser: User) => async (dispatch: Dispatch<Action>) => {
    const payload = await api.get(
      'v2_flight_reservations/upcoming',
      {},
      locale,
      authUser
    );

    if (payload) {
      dispatch({
        type: 'hafh/v2FlightReservations/SET_UPCOMING_FLIGHT_RESERVATIONS',
        payload,
      });
    }
  };

export const getPastFlightReservations =
  (locale: string, authUser: User) => async (dispatch: Dispatch<Action>) => {
    const payload = await api.get(
      'v2_flight_reservations/past',
      {},
      locale,
      authUser
    );

    if (payload) {
      dispatch({
        type: 'hafh/v2FlightReservations/SET_PAST_FLIGHT_RESERVATIONS',
        payload,
      });
    }
  };

export const getFlightReservation =
  (controlNumber: string, locale: string | undefined, authUser: User) =>
  async (dispatch: Dispatch<Action>) => {
    const payload = await api.get(
      `v2_flight_reservations/${controlNumber}`,
      {},
      locale,
      authUser
    );
    if (payload) {
      dispatch({
        type: 'hafh/v2FlightReservations/SET_FLIGHT_RESERVATION',
        payload,
      });
    }
  };

export const cancelFlightReservation = async (
  id: number,
  locale: string | undefined,
  authUser: User
) => await api.put(`v2_flight_reservations/${id}/cancel`, {}, locale, authUser);

export const createFlightReservation =
  (postData: V2FlightReservationNewOutput, locale: string, authUser: User) =>
  async (dispatch: Dispatch<Action>): Promise<boolean> => {
    const payload = await api.post(
      `v2_flight_reservations`,
      postData,
      locale,
      authUser
    );

    if (payload) {
      dispatch({
        type: 'hafh/v2FlightReservations/SET_FLIGHT_RESERVATION',
        payload,
      });
    }

    return payload;
  };

export default V2FlightReservations;
