import apiClient, { API_TYPES } from '@services/hafh/api';
import { User } from 'firebase/auth';

const api = apiClient(API_TYPES.API);

const initialState = {
  popular_properties: [],
  recommended_properties: [],
};

export const UPDATE_RECOMMENDED_PROPERTIES =
  'hafh/recommendedProperties/UPDATE_RECOMMENDED_PROPERTIES';

const RecommendedProperties = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RECOMMENDED_PROPERTIES: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

export const updateRecommendedProperties = (
  recommendedProperties = initialState
) => ({
  payload: recommendedProperties,
  type: UPDATE_RECOMMENDED_PROPERTIES,
});

export const getRecommendedProperties =
  (authUser: User, locale: string, property_ids: Array<number>) =>
  async (dispatch: any) => {
    const recommendedProperties = await api.get(
      'properties/rankings/recommended',
      {
        property_ids,
      },
      locale,
      authUser
    );

    if (recommendedProperties) {
      dispatch(updateRecommendedProperties(recommendedProperties));

      return recommendedProperties;
    }
  };

export default RecommendedProperties;
