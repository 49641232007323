/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = [];

export const UPDATE_USED_COINS_RANKING =
  'hafh/usedCoinsRanking/UPDATE_USED_COINS_RANKING';

const UsedCoinsRanking = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USED_COINS_RANKING:
      return [...action.payload];
    default:
      return state;
  }
};

export const updateUsedCoinsRanking = (usedCoinsRanking = []) => ({
  type: UPDATE_USED_COINS_RANKING,
  payload: usedCoinsRanking,
});

export const getUsedCoinsRanking =
  (locale, regionKey = 'allRegion', authUser) =>
  async (dispatch) => {
    const url = `properties/rankings/used_coins${
      regionKey === 'allRegion' ? '' : `?region=${regionKey}`
    }`;
    const usedCoinsRanking = await api.get(url, {}, locale, authUser);

    if (usedCoinsRanking) {
      dispatch(updateUsedCoinsRanking(usedCoinsRanking));
      return usedCoinsRanking;
    }
  };

export default UsedCoinsRanking;
