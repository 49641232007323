import apiClient, { API_TYPES } from '@services/hafh/api';
import { Neighbor } from '@services/hafh/types/generated';
import {
  Conversation,
  LANG_LOCALE,
  Message as MessageType,
} from '@utils/types';
import { User } from 'firebase/auth';
import { Action, Dispatch } from 'redux';

const api = apiClient(API_TYPES.API);

type MessageState = {
  conversation: Conversation | null;
  conversations: Conversation[];
};

const initialState = {
  conversation: null,
  conversations: [],
};

// action types
const SET_CONVERSATIONS = 'hafh/message/SET_CONVERSATIONS' as const;
const SET_CONVERSATION = 'hafh/message/SET_CONVERSATION' as const;

const UPDATE_MESSAGE = 'hafh/message/UPDATE_MESSAGE' as const;

// reducers
const Message = (
  state: MessageState = initialState,
  action:
    | ReturnType<typeof setConversations>
    | ReturnType<typeof setConversation>
    | ReturnType<typeof updateMessage>
) => {
  if (action.type === SET_CONVERSATIONS) {
    return {
      ...state,
      conversations: action.payload,
    };
  }

  if (action.type === SET_CONVERSATION) {
    return {
      ...state,
      conversation: action.payload,
    };
  }

  if (action.type === UPDATE_MESSAGE) {
    return {
      ...state,
      conversation: state.conversation
        ? {
            ...state.conversation,
            messages: [...state.conversation.messages, action.payload],
          }
        : null,
    };
  }

  return state;
};

// actions creators
export const setConversations = (conversations: Conversation[]) => ({
  payload: conversations,
  type: SET_CONVERSATIONS,
});

export const setConversation = (conversation: Conversation) => ({
  payload: conversation,
  type: SET_CONVERSATION,
});

export const updateMessage = (message: MessageType) => ({
  payload: message,
  type: UPDATE_MESSAGE,
});

// actions
export const getConversations =
  (authUser: User, locale: LANG_LOCALE) =>
  async (dispatch: Dispatch<Action>) => {
    const conversations = await api.get('conversations', {}, locale, authUser);

    if (conversations) {
      dispatch(setConversations(conversations));
    }
  };

export const getConversation =
  (conversationId: string, authUser: User, locale: LANG_LOCALE) =>
  async (dispatch: Dispatch<Action>) => {
    const conversation = await api.get(
      `conversations/${conversationId}`,
      {},
      locale,
      authUser
    );

    if (conversation) {
      dispatch(setConversation(conversation));
    }
  };

export const sendMessage =
  (
    conversationId: string,
    messageContent: string,
    neighbor: Neighbor,
    authUser: User,
    locale: LANG_LOCALE
  ) =>
  async (dispatch: Dispatch<Action>) => {
    const payload = {
      content: messageContent,
      conversation_id: conversationId,
      sender_id: neighbor.id,
      sender_type: 'Neighbor',
    };

    const newMessage = await api.post('messages', payload, locale, authUser);

    if (newMessage) {
      dispatch(updateMessage(newMessage));
    }
  };

export default Message;
