import apiClient, { API_TYPES } from '@services/hafh/api';
import { PropertyPlansCheckReservable } from '@services/hafh/types/generated';
import { LANG_LOCALE } from '@utils/types';
import { User } from 'firebase/auth';
import { Moment } from 'moment-timezone';
import { Action, Dispatch } from 'redux';

const api = apiClient(API_TYPES.API);

type checkReservableState = PropertyPlansCheckReservable & {
  isLoadingCheckReservable: boolean;
};

const initialState: {
  checkReservable: checkReservableState;
  // TODO update to use vacancy swagger type
  vacancy: any;
} = {
  checkReservable: {
    adjustment_coin: null,
    can_use_payment_options: null,
    isLoadingCheckReservable: false,
    min_coin_per_night: {
      base: 0,
      vip: null,
    },
    purchase_coin_limit: {
      amount: null,
      coin: null,
      currency: {
        code: '',
        decimal_point: 0,
        symbol: '',
      },
    },
    total_coin: null,
    vip: {
      discount_rate: 0,
      rank: null,
    },
    vip_total_coin: null,
  },
  vacancy: {},
};

export const UPDATE_PROPERTY_PLAN_VACANCY =
  'hafh/propertyPlanVacancy/UPDATE_PROPERTY_PLAN_VACANCY' as const;

export const UPDATE_CHECK_RESERVABLE =
  'hafh/propertyPlanVacancy/UPDATE_CHECK_RESERVABLE' as const;

export const UPDATE_IS_LOADING_CHECK_RESERVABLE =
  'hafh/propertyPlanVacancy/UPDATE_IS_LOADING_CHECK_RESERVABLE' as const;

const PropertyPlan = (
  state = initialState,
  // TODO: implement Swagger
  action:
    | ReturnType<typeof updatePropertyPlanVacancy>
    | ReturnType<typeof updateCheckReservable>
    | ReturnType<typeof updateIsLoadingCheckReservable>
) => {
  switch (action.type) {
    case UPDATE_PROPERTY_PLAN_VACANCY: {
      return {
        ...state,
        vacancy: action.payload,
      };
    }

    case UPDATE_CHECK_RESERVABLE: {
      return {
        ...state,
        checkReservable: {
          ...state.checkReservable,
          ...action.payload,
        },
      };
    }

    case UPDATE_IS_LOADING_CHECK_RESERVABLE: {
      return {
        ...state,
        checkReservable: {
          ...state.checkReservable,
          isLoadingCheckReservable: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export const updatePropertyPlanVacancy = (propertyPlanVacancy = {}) => ({
  payload: propertyPlanVacancy,
  type: UPDATE_PROPERTY_PLAN_VACANCY,
});

export const updateCheckReservable = (
  checkReservable: PropertyPlansCheckReservable
) => ({
  payload: checkReservable,
  type: UPDATE_CHECK_RESERVABLE,
});

export const updateIsLoadingCheckReservable = (
  isLoadingCheckReservable: boolean
) => ({
  payload: isLoadingCheckReservable,
  type: UPDATE_IS_LOADING_CHECK_RESERVABLE,
});

export const getPropertyPlanVacancy =
  (propertyPlanId: number) => async (dispatch: Dispatch<Action>) => {
    const propertyPlanVacancy = await api.get(
      `property_plans/${propertyPlanId}/vacancy`,
      {},
      ''
    );

    if (propertyPlanVacancy) {
      dispatch(updatePropertyPlanVacancy(propertyPlanVacancy));

      return propertyPlanVacancy;
    }
  };

export const getCheckReservable =
  (
    {
      checkin,
      checkout,
      guestNumber,
      isSplitBooking,
      propertyPlanIds,
    }: {
      checkin?: Moment;
      checkout?: Moment;
      guestNumber: number;
      isSplitBooking?: boolean;
      propertyPlanIds: Array<number>;
    },
    locale: LANG_LOCALE,
    authUser: User
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateIsLoadingCheckReservable(true));
    const checkReservable: PropertyPlansCheckReservable = await api.get(
      'property_plans/check_reservable',
      {
        check_in_date: checkin,
        check_out_date: checkout,
        guest_number: guestNumber,
        is_split_reservation: isSplitBooking,
        property_plan_ids: propertyPlanIds,
      },
      locale,
      authUser
    );

    if (checkReservable) {
      dispatch(updateCheckReservable(checkReservable));
    }

    return dispatch(updateIsLoadingCheckReservable(false));
  };

export default PropertyPlan;
