/* eslint-disable */
import { PayloadAction } from '@utils/types';
import { User } from 'firebase/auth';

// @ts-ignore
const initialState: User = {};

// action types
export const SET_AUTH_USER = 'hafh/authUser/SET_AUTH_USER';

// reducers
const authUser = (state = initialState, action: PayloadAction<User>) => {
  switch (action.type) {
    case SET_AUTH_USER:
      return action.payload;
    default:
      return state;
  }
};

// actions
export const setAuthUser = (authUser = initialState) => ({
  type: SET_AUTH_USER,
  payload: authUser,
});

export default authUser;
